/**
 * Generate a random UUID.
 *
 * As this is not used for security purposes we can rely on not-so-safe rnadom
 * numbers from Math.random().
 */
function uuid() {
  let d = new Date().getTime()
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16
    if (d > 0) {
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

/**
 * Provides the random UUID to identify a user across client and server side
 * for Nuxt
 *
 * This is only used Nuxt internally and has no connection to a Drupal
 * user/session.
 */
export default function () {
  const cookieDomain = useRuntimeConfig().public.cookieDomain

  const session = useCookie('_nsc', {
    // Restrict the cookie to our known cookie domain.
    domain: cookieDomain,
    // This is required so that when the navigator is embedded in an iframe,
    // the cookie is also sent. Or else some navigator API calls will not
    // work.
    sameSite: 'none',
    secure: true,
  })

  if (!session.value) {
    session.value = uuid()
  }

  const token = computed(() => {
    return session.value
  })

  return { token }
}
