import { ValidFacetKey } from '../helpers/pharmacies-api/types'

export default function () {
  const route = useRoute()
  const { $texts } = useNuxtApp()

  const buildFacetTermKey = (facet: ValidFacetKey, term: string) => {
    return `${facet}:${term}`
  }

  const getCurrentFacets = (queryValue: any) => {
    return typeof queryValue === 'string' ? queryValue : ''
  }

  const calculateFacets = (
    currentFacets: string,
    key: string,
    isSelected: boolean,
  ) => {
    return isSelected
      ? currentFacets
          .split(',')
          .filter((v) => v !== key)
          .join(',') || undefined
      : (currentFacets ? currentFacets + ',' : '') + key
  }

  const buildFacetTermLinkBase = (
    facet: ValidFacetKey,
    term: string,
    isSelected = false,
    additionalQuery: any = {},
  ) => {
    const key = buildFacetTermKey(facet, term)
    const currentFacets = getCurrentFacets(route.query.facets)
    const facets = calculateFacets(currentFacets, key, isSelected)

    return {
      name: 'navigator',
      params: route.params,
      query: {
        ...route.query,
        facets,
        ...additionalQuery,
        page: undefined,
      },
    }
  }

  const buildFacetTermLink = (
    facet: ValidFacetKey,
    term: string,
    isSelected = false,
  ) => {
    return buildFacetTermLinkBase(facet, term, isSelected)
  }

  const buildFacetTermLinkWithTextQuery = (
    facet: ValidFacetKey,
    term: string,
    searchTerm: string,
    isSelected = false,
  ) => {
    return buildFacetTermLinkBase(facet, term, isSelected, { text: searchTerm })
  }

  const getFacetLabel = (id: ValidFacetKey) => {
    if (id === 'canton') {
      return $texts('navigator.facetCanton', 'Kanton')
    } else if (id === 'currently_open') {
      return $texts('navigator.facetCurrentlyOpen', 'Jetzt geöffnet')
    } else if (id === 'services') {
      return $texts('navigator.facetServices', 'Dienstleistungen')
    } else if (id === 'positions') {
      return $texts('navigator.facetPositions', 'Bildungsstätten')
    } else if (id === 'has_open_positions') {
      return $texts('navigator.openPositions', 'Offene Stellen')
    } else if (id === 'brand' || id === 'chain' || id === 'groups') {
      return $texts('navigator.facetBrand', 'Marke')
    }
    return id
  }

  const isSingleFacet = (id: ValidFacetKey) => {
    return id === 'currently_open' || id === 'has_open_positions'
  }

  return {
    buildFacetTermLink,
    buildFacetTermLinkWithTextQuery,
    getFacetLabel,
    isSingleFacet,
  }
}
