<template>
  <form
    class="md:flex w-full md:h-50 lg:h-60 rounded-[2rem] bg-clip-padding items-center shadow-lg"
    @submit.prevent="onFormSubmit"
  >
    <!-- Input field for the fulltext. -->
    <NavigatorSearchInput
      :text="text"
      :placeholder="
        $texts(
          'navigator.searchInputPlaceholder',
          'Name, Dienstleistung, Stichwort...',
        )
      "
      icon="search"
      class="md:flex-[3] md:rounded-tl-[2rem] md:rounded-bl-[2rem]"
      :get-suggestions="getSearchSuggestion"
      :show-prefix-item="true"
      @select-item="selectSearchTerm"
      @raw-search="rawSearch"
    >
      <template #item="{ suggestion }">
        <div
          v-if="suggestion.isPrefix"
          class="uppercase font-medium text-green-alt text-sm"
        >
          <!-- TODO: Check this translation for other languages as well -->
          {{ $texts('navigator.searchForPharmacies', 'Apotheken nach') }} «{{
            suggestion.label
          }}» {{ $texts('navigator.search', 'durchsuchen') }}
        </div>
        <NavigatorSearchBarSuggestionSearch
          v-else
          :type="suggestion.data.type"
          :label="suggestion.label"
        />
      </template>
    </NavigatorSearchInput>

    <!-- Input field for the location. -->
    <NavigatorSearchInput
      :placeholder="$texts('navigator.searchLocationText', 'PLZ, Ort, ...')"
      icon="pin"
      :get-suggestions="getLocalitySuggestions"
      :text="renderedLocation"
      :show-location-button="true"
      :input-timeout="400"
      class="md:flex-[2] mobile-only:-mt-1 md:border-x md:border-x-gray-300"
      @select-item="selectLocality"
    >
      <template #item="{ suggestion }">
        <div class="font-medium">{{ suggestion.data.title }}</div>
        <div>{{ suggestion.data.context }}</div>
      </template>

      <template #after-input>
        <button
          v-if="!location"
          class="absolute top-1/2 right-[0.75em] -translate-y-1/2 is-text-sized"
          @click="setCurrentLocation"
        >
          <SpriteSymbol
            name="finder"
            class="is-text-sized fill-green-alt hover:fill-lime"
          />
        </button>
      </template>
    </NavigatorSearchInput>

    <!-- CTA Submit button -->
    <div
      class="hidden h-full md:flex items-center p-10 rounded-tr-[2rem] rounded-br-[2rem] bg-white"
    >
      <ButtonStyle v-slot="{ classes }">
        <button class="is-narrow mobile-only:w-full" :class="classes">
          {{ $texts('search', 'Suchen') }}
        </button>
      </ButtonStyle>
    </div>
  </form>
</template>

<script lang="ts" setup>
import type { LocalitySearchItem } from '~/sites/pharmago/server/api/localities/search'
import type { LocalitySelectItem } from '~/sites/pharmago/server/api/localities/select'
import type { SearchSuggestionItem } from '~/sites/pharmago/server/api/suggestions'
import { SearchInputSuggestion } from '~/sites/pharmago/types/navigator'
import useNavigator from '@pharmago/composables/useNavigator'
import { ValidFacetKey } from '~/sites/pharmago/helpers/pharmacies-api/types'

const { $texts } = useNuxtApp()
const route = useRoute()
const router = useRouter()
const language = useCurrentLanguage()
const { buildFacetTermLink } = useNavigator()

// Make sure we have a session cookie.
useNuxtSession()

const location = useQueryString('location')
const text = useQueryString('text')

const renderedLocation = computed(() => {
  if (location.value === 'current') {
    return $texts('navigator.currentLocation', 'Aktueller Standort')
  }
  return location.value
})

type LocalitySuggestion = SearchInputSuggestion<{
  title: string
  context: string
  mapboxId: string
}>

type SearchSuggestion = SearchInputSuggestion<{
  id: string
  type?: ValidFacetKey
}>

function getLocalitySuggestions(search: string): Promise<LocalitySuggestion[]> {
  return $fetch<LocalitySearchItem[]>('/api/localities/search', {
    query: { text: search, language: language.value },
  }).then((result) => {
    return result.map((v) => {
      return {
        label: v[0] + ' ' + v[1],
        data: {
          title: v[0],
          context: v[1],
          mapboxId: v[2],
        },
      }
    })
  })
}

function getSearchSuggestion(search: string): Promise<SearchSuggestion[]> {
  return $fetch<SearchSuggestionItem[]>('/api/suggestions', {
    query: { text: search, language: language.value },
  }).then((result) => {
    return result.map((v) => {
      return {
        label: v[0],
        data: {
          id: v[1],
          type: v[2],
        },
      }
    })
  })
}

async function selectLocality(item: LocalitySuggestion) {
  const result = await $fetch<LocalitySelectItem | undefined>(
    '/api/localities/select',
    {
      query: {
        id: item.data.mapboxId,
      },
    },
  )

  if (!result) {
    return
  }

  router.push({
    name: 'navigator',
    params: route.params,
    query: {
      ...route.query,
      location: item.label,
      coordinates: [result.lat, result.lon].join(','),
      page: undefined,
    },
  })
}

/**
 * Method called when selecting a suggestion from the fulltext search.
 */
function selectSearchTerm(item: SearchSuggestion) {
  if (item.data.type) {
    return router.push(buildFacetTermLink(item.data.type, item.data.id))
  }

  // Fallback to search term.
  rawSearch(item.label)
}

const onFormSubmit = () => {
  const searchInputEl = document.getElementById(
    'searchInputEl',
  ) as HTMLInputElement | null
  if (searchInputEl) {
    rawSearch(searchInputEl.value)
  }
}

/**
 * Method called when hitting enter on the fulltext search input.
 */
function rawSearch(term: string) {
  router.push({
    name: 'navigator',
    params: route.params,
    query: {
      ...route.query,
      page: undefined,
      text: term || undefined,
    },
  })
}

function getCurrentCoordinates(): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      throw new Error('Not supported')
    }
    navigator.geolocation.getCurrentPosition(
      (v) => resolve([v.coords.latitude, v.coords.longitude].join(',')),
      (e) => reject(e),
    )
  })
}

async function setCurrentLocation() {
  try {
    const coordinates = await getCurrentCoordinates()
    await router.push({
      name: 'navigator',
      query: {
        location: 'current',
        coordinates,
      },
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to get coordinates.')
  }
}
</script>
