<template>
  <div>
    <div class="uppercase font-medium text-xs">{{ typeLabel }}</div>
    <h3 class="font-medium leading-none xl:text-md">{{ label }}</h3>
  </div>
</template>

<script lang="ts" setup>
import { ValidFacetKey } from '~/sites/pharmago/helpers/pharmacies-api/types'

const props = defineProps<{
  label: string
  type: ValidFacetKey
}>()

const { getFacetLabel } = useNavigator()

const typeLabel = computed(() => getFacetLabel(props.type))
</script>
