<template>
  <div
    v-click-away="closeSuggestions"
    class="relative group mobile-only:h-50 md:h-full bg-white focus-within:z-50"
  >
    <div
      class="text-md h-full text-gray-500 focus-within:text-green-alt relative flex items-center"
    >
      <SpriteSymbol
        :name="icon"
        class="absolute top-1/2 left-[0.75em] -translate-y-1/2 is-text-sized"
      />
      <input
        id="searchInputEl"
        ref="inputEl"
        v-model="localInput"
        type="text"
        :placeholder="placeholder"
        class="block text-gray-900 focus:text-green-alt h-full pl-[2.25em] pr-10 bg-white rounded-[2rem] border-transparent font-medium focus:outline-none focus:border-none focus:ring-0 focus:ring-offset-0 border-0 w-full rounded-tl-[2rem]"
        :class="{ '!rounded-bl-none': showSuggestions }"
        autocomplete="off"
        name="searchtext"
        @focus="onFocus"
        @input="onFocus"
        @keydown.enter.prevent="clickTerm(activeIndex)"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.tab="suggestionsVisible = false"
      />
      <button
        v-if="localInput"
        class="py-15 pr-10"
        :class="{ 'right-30': $slots['after-input'] }"
        :title="$texts('navigator.clearInputValue', 'Eingabe löschen')"
        @click="clearInput"
      >
        <SpriteSymbol name="close" class="is-text-sized" />
      </button>
      <slot name="after-input"></slot>
    </div>
    <div
      v-if="showSuggestions"
      id="searchSuggestions"
      class="absolute top-full left-0 bg-white shadow-lg z-50 w-full text-left focus:outline-none focus:border-none focus:ring-0 focus:ring-offset-0 rounded-b-[5px] border-t border-t-gray-300"
      tabindex="0"
      @mouseleave="activeIndex = -1"
    >
      <ul>
        <li
          v-for="(suggestion, index) in mappedSuggestions"
          :key="index"
          class="cursor-pointer focus:outline-none focus:border-none focus:ring-0 focus:ring-offset-0 px-20 py-10 pl-20"
          :class="{ 'is-active text-green bg-gray-50': index === activeIndex }"
          tabindex="0"
          @click="clickTerm(index)"
          @mouseenter="activeIndex = index"
        >
          <slot :suggestion="suggestion" name="item">
            {{ suggestion.label }}
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { directive as vClickAway } from 'vue3-click-away'
import type { SearchInputSuggestion } from '~/sites/pharmago/types/navigator'

const props = defineProps<{
  placeholder: string
  icon: string
  text: string
  showPrefixItem?: boolean
  getSuggestions: (text: string) => Promise<SearchInputSuggestion<any>[]>
  inputTimeout?: number
}>()

const emit = defineEmits(['selectItem', 'rawSearch'])

// The <input> element.
const inputEl = ref<HTMLInputElement | null>(null)

// The current input value.
const localInput = ref(props.text)

// If the suggestions dropdown should be visible.
const suggestionsVisible = ref(false)

// The currently highlighted suggestion index.
const activeIndex = ref(0)

// The fetched array of suggestions.
const suggestions = ref<SearchInputSuggestion[]>([])

const mappedSuggestions = computed<SearchInputSuggestion[]>(() => {
  if (props.showPrefixItem && localInput.value) {
    return [
      {
        label: localInput.value,
        data: localInput.value,
        isPrefix: true,
      },
      ...suggestions.value,
    ]
  }
  return suggestions.value
})

// setTimeout handle for the suggestion search.
let timeout: any = null

const totalSuggestions = computed(() => {
  return mappedSuggestions.value.length
})

const showSuggestions = computed(
  () => suggestionsVisible.value && totalSuggestions.value && localInput.value,
)

// Load suggestions when the local input changes.
watch(localInput, () => {
  loadSuggestions()
})

watch(
  () => props.text,
  (v) => {
    localInput.value = v
    suggestionsVisible.value = false
  },
)

function loadSuggestions() {
  clearTimeout(timeout)
  const text = localInput.value
  if (!text || text.length < 3) {
    suggestions.value = []
    return
  }
  timeout = setTimeout(async () => {
    if (document.activeElement !== inputEl.value) {
      return
    }
    suggestions.value = await props.getSuggestions(text)
    activeIndex.value = 0
  }, props.inputTimeout || 140)
}

function modulo(n: number, m: number) {
  return ((n % m) + m) % m
}

const onArrowDown = () => {
  activeIndex.value = modulo(activeIndex.value + 1, totalSuggestions.value)
}

const onArrowUp = () => {
  activeIndex.value = modulo(activeIndex.value - 1, totalSuggestions.value)
}

function clickTerm(index: number) {
  closeSuggestions()
  const term = mappedSuggestions.value[index]
  if (term && !term.isPrefix) {
    localInput.value = ''
    emit('selectItem', term)
    return
  }

  emit('rawSearch', localInput.value)
}

function onFocus() {
  activeIndex.value = 0
  suggestionsVisible.value = true
}

function closeSuggestions() {
  suggestionsVisible.value = false
}

function clearInput() {
  localInput.value = ''
}
</script>
